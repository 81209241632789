import React from 'react';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { useHistory } from "react-router-dom";
import GraphicCard from '../../images/nana-dua-aVeKubCF-48-unsplash.jpg';
import GraphicCard1 from '../../images/nana-dua-1-Sfd9QSPi4-unsplash (1).jpg';
import PC from '../../images/constant-loubier-7lzIyp2Ork4-unsplash.jpg';
import PC1 from '../../images/pascal-brandle-rGklzMk-58g-unsplash.jpg';
import GamingKeyboard from '../../images/stefan-gall-yQnyP1g5zl4-unsplash.jpg';
// import sliderImg from '../../images/tianyi-ma-WiONHd_zYI4-unsplash.jpg';
// import sliderImg1 from '../../images/clay-banks-TQYTWfN1b7M-unsplash.jpg';
// import sliderImg2 from '../../images/alienware-ickPGVMMr98-unsplash.jpg';
// import sliderImg3 from '../../images/kari-shea-1SAnrIxw5OY-unsplash.jpg';
import styled from 'styled-components';
import SectionHeader from '../../components/section-header';
import { Accordion, AccordionTab } from 'primereact/accordion';

const SliderCation = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #f8f9fa;
    padding: 1rem;
    & > h3 {
        color: #6fd530;
    }
    & > p {
        color: #38BFF5;
    }
`;

const CatoCard = styled.div`
    cursor: pointer;
    & .p-card-header > img {
        &:hover {
            // transform: scale(1.2);
        }
    }
`;

function Homepage() {
    let history = useHistory();
    const images = [
        {image : GraphicCard, title: 'Graphics Card', description: 'Nvidia GTX 2080'},
        {image : GamingKeyboard, title: 'Gaming keyboard and mouse', description: 'RGB back lit full gaming keyboard by AMD'},
        {image : GraphicCard1, title: 'Graphics Card', description: 'Nvidia GTX 1080 Ti'},
        {image : PC, title: 'PC Cabinet', description: 'RGB back lit full gaming Cabinet by AMD'},
    ];

    const prods = [
        {image : GraphicCard, title: 'Graphics Card', description: 'Nvidia GTX 2080'},
        {image : GamingKeyboard, title: 'Gaming Accessories', description: 'RGB back lit full gaming keyboard by AMD'},
        {image : GraphicCard1, title: 'Graphics Card', description: 'Nvidia GTX 1080 Ti'},
        {image : PC, title: 'PC Cabinet', description: 'RGB back lit full gaming Cabinet by AMD'},
        {image : GraphicCard, title: 'Graphics Card', description: 'Nvidia GTX 2080'},
        {image : GamingKeyboard, title: 'Gaming Accessories', description: 'RGB back lit full gaming keyboard by AMD'},
        {image : GraphicCard1, title: 'Graphics Card', description: 'Nvidia GTX 1080 Ti'},
        {image : PC, title: 'PC Cabinet', description: 'RGB back lit full gaming Cabinet by AMD'},
    ];

    const CardHeader = (imgSrc, customHeight = 'auto') => <img src={imgSrc} alt={`${imgSrc}`} alt={imgSrc} height={customHeight} style={{ objectFit: 'cover' }} />

    const productTemplate = (product) => {
        return (
            <div className="p-mb-3" style={{ position: 'relative' }}>
                <img src={product.image} style={{ objectFit: 'cover' }} height="509px" width="100%" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.name} />
                <SliderCation>
                    <h3 className="p-mb-2">{product.title}</h3>
                    <p>{product.description}</p>
                </SliderCation>
            </div>
        );
    }

    const productCardTemplate = (product) => {
        const footer = <p className="p-m-0" style={{ color: '#38BFF5' }} >Know more</p>;
        return (
            <CatoCard  className="p-p-1">
                <Card className="p-shadow-5 custom-card" header={CardHeader(product.image, '200px')} title={product.title} footer={footer} ></Card>
            </CatoCard>
        );
    }

    const CatogoryCard = ({headerImg, title}) => {
        const footer = <p className="p-m-0" style={{ color: '#38BFF5' }} >Know more</p>;
        return (
            <Card className="p-shadow-5 custom-card" header={CardHeader(headerImg, '200px')} title={title} footer={footer} ></Card>
        )
    }

    return (
        <div id="homepage" className="p-my-3">
            <SectionHeader title="Hot Pics" position="left" />
            <div className="p-grid p-mt-1" style={{ height: '650px' }}>
                <div id="left-section" className="p-col-12 p-sm-6">
                    <Card className="p-shadow-5" header={CardHeader(PC1,'509px')} title="Graphics Card" subTitle="Nvidia Geforce GTX 2080" className="custom-card" ></Card>
                </div>
                <div id="left-section" className="p-col-12 p-sm-6">
                    <div className="p-grid">
                        <div id="left-section" className="p-col-12 p-sm-6">
                            <Card className="p-shadow-5" header={CardHeader(GraphicCard1, '200px')}  title="Graphics Card" subTitle="Nvidia GTX 2060" className="custom-card">
                                
                            </Card>
                        </div>
                        <div id="left-section" className="p-col-12 p-sm-6">
                            <Card className="p-shadow-5" header={CardHeader(PC, '200px')}  title="PC Cabinet" subTitle="ACER - rgb PC Cabinet" className="custom-card">
                            </Card>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div id="left-section" className="p-col-12 p-sm-6">
                            <Card className="p-shadow-5" header={CardHeader(GamingKeyboard, '200px')}  title="Gaming Keyboard" subTitle="Razor 7 Rx4600" className="custom-card">
                                
                            </Card>
                        </div>
                        <div id="left-section" className="p-col-12 p-sm-6">
                            <Card className="p-shadow-5" header={CardHeader(GraphicCard, '200px')}  title="Graphics Card" subTitle="Nvidia GTX 2080" className="custom-card">
                                
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-grid p-mt-1">
                <Carousel value={images} numVisible={1} numScroll={1} itemTemplate={productTemplate} indicatorsContentClassName="p-d-none" containerClassName="carousel-custom" />
            </div>
            <SectionHeader title="Popular Categories" position="left" />
            <div className="p-grid p-mt-1">
                <div id="left-section" className="p-col-12 p-sm-12">
                    <div className="p-grid">
                        <div id="left-section" className="p-col-12 p-sm-3">
                            <CatogoryCard headerImg={GraphicCard1} title="Graphics Card" />
                        </div>
                        <div id="left-section" className="p-col-12 p-sm-3">
                            <CatogoryCard headerImg={PC} title="PC Cabinet" />
                        </div>
                        <div id="left-section" className="p-col-12 p-sm-3">
                            <CatogoryCard headerImg={GamingKeyboard} title="Gaming Keyboard" />
                        </div>
                        <div id="left-section" className="p-col-12 p-sm-3">
                            <CatogoryCard headerImg={GraphicCard} title="Graphics Card" />
                        </div>
                    </div>
                </div>
            </div>
            <SectionHeader title="Products you may like" position="left" />
            <div className="p-grid p-mt-1">
                <Carousel value={prods} numVisible={3} numScroll={1} itemTemplate={productCardTemplate} indicatorsContentClassName="p-d-none"  />
            </div>
        </div>
    )
}

export default Homepage;
