import React from 'react';
import './style.css';

function ComingSoon() {
    return (
        <div id="coming-soon">
           <div className="main-container">
                <div className="overlay-skew1"></div>
                <section className="left-section">
                    <div className="wrapper">
                        <h1 className="main-header">Something <br/> awesome is on <br/> its way!</h1>
                        <p className="sub-heading">We are working on a new and exciting product that we think you'll really like.</p>
                    </div>
                </section>
            </div> 
        </div>
    )
}

export default ComingSoon;
