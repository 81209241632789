import React, { useState } from 'react';
import styled from 'styled-components';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
const BrandLogo = styled.div`
    position: absolute;
    left: calc(50% - 46px);
    margin: -32px 0 0 0;
    & > h1 {
        font-size: 45px;
        color:  #6fd530;
        text-shadow: 0px 0px 20px #6fd530, 0px 0px 25px #38BFF5;
    }
`;

const LogoBottom = styled.div`
    border-left: 65px solid transparent;
    border-right: 65px solid transparent;
    border-top: 36.6px solid rgba(0,0,0,.6);
    filter: drop-shadow(0 2px 4px rgba(22,22,24,.25));
    position: absolute;
    top: 50px;
    z-index: -1;
    right: calc(50% - 65px);
    margin-left: auto;
    margin-right: auto;
`;

const NavBar = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
        float: left;
        padding: 12px 15px;
        & > a {
            text-decoration: none;
            color: #38BFF5;
            text-shadow: 0px 0px 20px #38BFF5, 0px 0px 25px #38BFF5;
            text-transform: uppercase;
            &:hover {
                color: #6fd530;
                text-shadow: 0px 0px 20px #6fd530, 0px 0px 25px #6fd530;
            }
        }
    }
    &.navbar-left {
        position: absolute;
        right: 50%;
        margin-right:70px;
    }
    &.navbar-right {
        position: absolute;
        left: 50%;
        margin-left: 70px;
    }
`;

const CartIcon = styled.i`
    position: fixed;
    right: 15px;
    top: 11px;
    font-size: 24px;
    &:hover {
        cursor: pointer;
    }
`;
const MoreIcon = styled.i`
    position: fixed;
    left: 15px;
    top: 11px;
    font-size: 24px;
    &:hover {
        cursor: pointer;
    }
`;

function Header({url}) {
    const [showLeftSlider, setshowLeftSlider] = useState(false);
    const [showRightSlider, setshowRightSlider] = useState(false);

    return (
        <div id="header">
            <div id="header-wrapper">
                <MoreIcon className="pi pi-align-justify" onClick={() => setshowLeftSlider(true)}></MoreIcon>
                <BrandLogo>
                    <h1>WM</h1>
                </BrandLogo>
                <LogoBottom />
                <div id="main-nav">
                    <NavBar className="navbar-left">
                        <li><a href={`${url}`}>Home</a></li>
                        <li><a href={`${url}/pc-builder`}>PC Builder</a></li>
                        <li><a href={`${url}`}>Categories</a></li>
                    </NavBar>
                    <NavBar className="navbar-right">
                        <li><a href={`${url}`}>About Us</a></li>
                        <li><a href={`${url}`}>Contact Us</a></li>
                        <li><a href={`${url}`}>FAQ</a></li>
                    </NavBar>
                </div>
                <CartIcon className="pi pi-shopping-cart" onClick={() => setshowRightSlider(true)}></CartIcon>
            </div>
            <Sidebar visible={showLeftSlider} baseZIndex={1000000} onHide={() => setshowLeftSlider(false)}>
                
            </Sidebar>
            <Sidebar visible={showRightSlider} baseZIndex={1000000} position="right" onHide={() => setshowRightSlider(false)}>
                <h1 style={{ fontWeight: 'normal' }}>Right Sidebar</h1>
                <Button type="button" onClick={() => setshowRightSlider(false)} label="Save" className="p-button-success" style={{ marginRight: '.25em' }} />
                <Button type="button" onClick={() => setshowRightSlider(false)} label="Cancel" className="p-button-secondary" />
            </Sidebar>
        </div>
    );
}

export default Header;
