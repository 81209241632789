import React from 'react';
import styled from 'styled-components';


const SectionHeader = ({title, position}) => {
    const Title = styled.h2`
        float: ${position.toLowerCase() === 'right' ? 'right' : 'left' }
    `;

    return (
        <div className="p-grid  p-my-3">
            <div className="p-col-12 p-py-0">
                <Title className="p-text-uppercase p-m-0" >{title}</Title> 
            </div>
            <div className="p-col-12 p-py-0">
                <hr className="divider p-shadow-5" />    
            </div>
        </div>
    )
}

export default SectionHeader;
