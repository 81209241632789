import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
// import Footer from './components/footer';
import ComingSoon from './container/coming-soon';
import Header from './container/header';
import Homepage from './container/homepage';
import PcBuilder from './container/pcBuilder';

function App() {

  const MainApp = () => {
    let { path, url } = useRouteMatch();
    return (
      <div>
        <Header url={url} />
        <Switch>
          <Route path={`${path}/pc-builder`}>
            <div className="main container"><PcBuilder /></div>
          </Route>
          <Route exact path={path}>
            <div className="main container"><Homepage /></div>
          </Route>
          <Route path="*">
            <Redirect to="/" /> 
          </Route>
        </Switch>
        {/* <Footer /> */}
      </div>
    )
  };

  return (
      <Router>
        <Switch>
          <Route path="/dev">
            <MainApp />
          </Route>
          <Route exact path="/">
            <ComingSoon /> 
          </Route>
          <Route path="*">
            <ComingSoon /> 
          </Route>
        </Switch>
      </Router>
  );
}

export default App;
